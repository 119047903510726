<template>
    <div>
        <!--tabs video & document -->
        <div class="d-flex justify-content-between align-items-center mb-1">
            <b-button-group>
                <b-button
                    :variant="isQuestion ? 'primary' : 'outline-primary'"
                    @click="isQuestion = true"
                    >الفيديوهات</b-button
                >
                <b-button
                    :variant="isQuestion ? 'outline-primary' : 'primary'"
                    @click="isQuestion = false"
                    >المستندات</b-button
                >
            </b-button-group>
        </div>

        <div>
            <!--table question type Video | Document-->
            <ek-table
                :columns="columns"
                :items="
                    questionsBank.filter((obj) =>
                        isQuestion
                            ? obj.lessonType == 'Video'
                            : obj.lessonType == 'Document'
                    )
                "
                @details="goToDetails"
                @delete-selected="deleteQuestion"
            >
                <template slot="items.dateCreated" slot-scope="{ value }">
                    {{ new Date(value).toLocaleDateString() }}
                </template>

                <template slot="items.courseId" slot-scope="{ props }">
                    <span
                        v-if="courses.find((u) => u.id == props.row.courseId)"
                    >
                        {{
                            courses.find((u) => u.id == props.row.courseId).name
                        }}
                    </span>
                    <span v-else>
                        الكورس {{ props.row.courseId }} غير موجود
                    </span>
                </template>
            </ek-table>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
    components: {},
    computed: {
        ...mapState({
            questionList: ({ quiz }) => quiz.questionList,
            columns: ({ quiz }) => quiz.columns,
            courses: ({ courses }) => courses.course.courses,
        }),
        ...mapGetters(["questionsBank"]),
    },
    data: () => ({
        isQuestion: true,
    }),
    methods: {
        ...mapActions([
            "getQuestionList",
            "deleteQuestion",
            "getCoursList",
            "getGetUnRead",
        ]),
        goToDetails({ row }) {
            this.$router.push({ path: `quiz/${row.id}` });
        },
    },

    created() {
        this.getCoursList();
        this.getQuestionList();
        this.getGetUnRead();
    },
};
</script>
