var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-1"},[_c('b-button-group',[_c('b-button',{attrs:{"variant":_vm.isQuestion ? 'primary' : 'outline-primary'},on:{"click":function($event){_vm.isQuestion = true}}},[_vm._v("الفيديوهات")]),_c('b-button',{attrs:{"variant":_vm.isQuestion ? 'outline-primary' : 'primary'},on:{"click":function($event){_vm.isQuestion = false}}},[_vm._v("المستندات")])],1)],1),_c('div',[_c('ek-table',{attrs:{"columns":_vm.columns,"items":_vm.questionsBank.filter(function (obj) { return _vm.isQuestion
                        ? obj.lessonType == 'Video'
                        : obj.lessonType == 'Document'; }
                )},on:{"details":_vm.goToDetails,"delete-selected":_vm.deleteQuestion},scopedSlots:_vm._u([{key:"items.dateCreated",fn:function(ref){
                var value = ref.value;
return [_vm._v(" "+_vm._s(new Date(value).toLocaleDateString())+" ")]}},{key:"items.courseId",fn:function(ref){
                var props = ref.props;
return [(_vm.courses.find(function (u) { return u.id == props.row.courseId; }))?_c('span',[_vm._v(" "+_vm._s(_vm.courses.find(function (u) { return u.id == props.row.courseId; }).name)+" ")]):_c('span',[_vm._v(" الكورس "+_vm._s(props.row.courseId)+" غير موجود ")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }